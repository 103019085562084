import React from 'react'
import { BioBody, BioContainer, BioText, BioWrapper, Name } from '../elements'

export const Bio = () => {
    return (
        // <BioWrapper>
        //     <BioContainer>
                
        //     </BioContainer>
        // </BioWrapper>

        <BioBody>
            <Name>
                Sean Nordquist
            </Name>
            {/* <BioText>
                I am currently a software engineering intern at Bloomberg. I have previously worked at NYU IT and AAA Equities. 
            </BioText>
            <BioText>
                I have developed a couple of apps. 
            </BioText> */}
        </BioBody>
    )
}
